<p-toolbar *ngIf="showList">
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="button-with-tooltip-holder" *ngIf="showAddRecord()" [pTooltip]="getEditTooltip()" tooltipPosition="bottom">
			<p-button data-testid="entityLicenceAddButton" [disabled]="!allowAddRecord()" icon="pi pi-plus" label="Add" (onClick)="addClick($event)"></p-button>
		</div>
	</ng-template>
</p-toolbar>
<p-table
	*ngIf="showList"
	[value]="records"
	dataKey="BusinessEntityLicenceId"
	[rowHover]="true"
	responsiveLayout="scroll"
	[showCurrentPageReport]="true"
	[loading]="loading"
	[filterDelay]="0"
	#recordsTable>
	<ng-template pTemplate="header">
		<tr>
			<th pSortableColumn="LicenceHolderName">
				<div class="table-header-label">
					Licence Holder Name
					<p-sortIcon field="LicenceHolderName"></p-sortIcon>
					<p-columnFilter type="text" field="LicenceHolderName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="WorkServiceTypeName">
				<div class="table-header-label">
					Work / Service Type
					<p-sortIcon field="WorkServiceTypeName"></p-sortIcon>
					<p-columnFilter type="text" field="WorkServiceTypeName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="LicenceCertificateTypeName">
				<div class="table-header-label">
					Licence / Certificate Type
					<p-sortIcon field="LicenceCertificateTypeName"></p-sortIcon>
					<p-columnFilter type="text" field="LicenceCertificateTypeName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="LicenceCertificateNumber">
				<div class="table-header-label">
					Licence / Certificate #
					<p-sortIcon field="LicenceCertificateNumber"></p-sortIcon>
					<p-columnFilter type="text" field="LicenceCertificateNumber" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="ExpiryDate">
				<div class="table-header-label">
					Expiry
					<p-sortIcon field="ExpiryDate"></p-sortIcon>
					<p-columnFilter type="date" field="ExpiryDate" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th style="width: 3rem"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-item>
		<tr class="p-selectable-row">
			<td>
				{{ item.LicenceHolderName }}
			</td>
			<td>
				{{ item.WorkServiceTypeName }}
			</td>
			<td>
				{{ item.LicenceCertificateTypeName }}
			</td>
			<td>
				{{ item.LicenceCertificateNumber }}
			</td>
			<td *ngIf="!item.ExpiryDate">n/a</td>
			<td *ngIf="item.ExpiryDate">
				<span [innerHTML]="item.ExpiryDate | formatDate : region.RegionCode : region.DateFormat"></span>
			</td>
			<td style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					data-testid="entityLicenceExpandButton"
					type="button"
					pButton
					pRipple
					pTooltip="Expand"
					tooltipPosition="bottom"
					(click)="expandClick(item)"
					class="p-button-text p-button-rounded p-button-plain"
					[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
			</td>
		</tr>
	</ng-template>
</p-table>
<div *ngIf="showList" class="custom-pagination">
	<span>Showing {{ records == null ? 0 : records.length }} entries</span>
</div>
<app-entity-licence-detail
	*ngIf="!showList"
	[record]="selectedRecord"
	[fixedLicenceHolderName]="getFixedLicenceHolderName()"
	(editFinished)="editFinished()"
	(editCancelled)="editCancelled()"
	(detailsClosed)="detailsClosed()">
</app-entity-licence-detail>
