import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { MultiSelect } from 'primeng/multiselect';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, NavigationService } from 'src/app/core/services';
import { Globals } from '../../../globals';
import { AppMainComponent } from '../../main/app.main.component';

@Component({
	selector: 'app-topbar',
	templateUrl: './app.topbar.component.html',
	styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopBarComponent extends BaseComponent implements AfterViewInit {
	filterSelected: string;
	@ViewChild('viewByMultiselect') ms: MultiSelect;

	constructor(
		public appMain: AppMainComponent,
		appContext: AppContextService,
		public globals: Globals,
		private msalService: MsalService,
		private navigationService: NavigationService
	) {
		super(appContext);
	}

	ngAfterViewInit() {
		if (this.ms != null) {
			const originalRemoveOption = this.ms.removeOption;
			this.ms.removeOption = (...args) => {
				originalRemoveOption.apply(this.ms, args);
				this.onDropDownPanelHide();
			};
		}
	}

	getDisplayName(): string {
		return this.appContext.user.DisplayName;
	}

	getSubscriberName(): string {
		return this.appContext.user.SubscriberName;
	}

	showProfileMenu(): boolean {
		return this.appContext.user.SubscriberId != null;
	}

	logout() {
		this.appContext.clearUser();
		this.msalService.logout();
	}

	showDemoBadge(): boolean {
		return this.appContext?.user?.IsDemoSubscription;
	}

	showTestBadge(): boolean {
		return this.appContext?.user?.IsTestSubscription;
	}

	viewUserProfile() {
		this.navigationService.navigateToCurrentUserProfile();
	}

	viewSubscriptions() {
		this.navigationService.navigateToCurrentSubscriptions();
	}

	onDropDownPanelHide() {
		this.appContext.onClientCompanyChanged();
	}

	onDropdownChange(ev) {
		this.filterSelected = '';
		ev.value.forEach(dropdownSelected => {
			this.filterSelected += dropdownSelected + ';';
		});
		const previousSelection = this.appContext.getViewByFilter();
		this.appContext.previousClientCompanySelection = previousSelection;

		this.appContext.setSavedValue('ViewBy-Dropdown', this.filterSelected);
	}
}
