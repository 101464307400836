<div #addressSearch *ngIf="visible" class="container field p-float-label p-input-icon-right">
	<i class="pi pi-search"></i>
	<input
		pInputText
		id="addresstext"
		name="addresstext"
		class="input"
		[ngClass]="{ error: invalid }"
		placeholder=""
		type="text"
		(focusout)="setInput()"
		#addresstext
		(keyup)="inputChanged($event.target.value)"
		autocomplete="off" />
	<label for="addresstext" [ngClass]="{ error: invalid }">{{ label }}</label>
</div>
