<p-table
	#relatedPOTable
	*ngIf="showList"
	[value]="data"
	dataKey="data"
	[rowHover]="true"
	[rows]="50"
	[totalRecords]="totalRecords"
	[paginator]="true"
	[lazy]="true"
	responsiveLayout="scroll"
	[filterDelay]="0"
	[rowsPerPageOptions]="[10, 25, 50]"
	[showCurrentPageReport]="true"
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	[loading]="loading"
	[filterDelay]="0"
	(onLazyLoad)="loadData($event)">
	<ng-template pTemplate="header">
		<tr class="related-po-table-row">
			<th pSortableColumn="ReferenceName" data-testid="relatedPOReference">
				<div class="table-header-label">
					PO #<p-sortIcon field="ReferenceName"> </p-sortIcon>
					<p-columnFilter type="text" field="ReferenceName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="WorkOrderNumber" data-testid="relatedWOReference">
				<div class="table-header-label">
					WO #<p-sortIcon field="WorkOrderNumber"> </p-sortIcon>
					<p-columnFilter type="text" field="WorkOrderNumber" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="AssetNumber" data-testid="relatedPOName">
				<div class="table-header-label">
					Asset #<p-sortIcon field="AssetNumber"> </p-sortIcon>
					<p-columnFilter type="text" field="AssetNumber" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="AssetReference" data-testid="relatedScheduleName">
				<div class="table-header-label">
					Asset Name<p-sortIcon field="AssetReference"> </p-sortIcon>
					<p-columnFilter type="text" field="AssetReference" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="Street" data-testid="relatedAddress">
				<div class="table-header-label">
					Address<p-sortIcon field="Street"> </p-sortIcon>
					<p-columnFilter type="text" field="Street" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="Suburb" data-testid="suburb">
				<div class="table-header-label">
					Suburb<p-sortIcon field="Suburb"> </p-sortIcon>
					<p-columnFilter type="text" field="Suburb" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="BusinessEntityName" data-testid="suburb">
				<div class="table-header-label">
					Allocated To<p-sortIcon field="BusinessEntityName"> </p-sortIcon>
					<p-columnFilter type="text" field="BusinessEntityName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="StatusName" data-testid="relatedScheduleStatus">
				<div class="table-header-label">
					Status<p-sortIcon field="StatusName"> </p-sortIcon>
					<p-columnFilter type="text" field="StatusName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="ScheduledStartDate">
				<div class="table-header-label">
					Scheduled Start<p-sortIcon field="ScheduledStartDate"></p-sortIcon>
					<p-columnFilter type="date" field="ScheduledStartDate" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="TargetDate">
				<div class="table-header-label">
					Due<p-sortIcon field="TargetDate"></p-sortIcon>
					<p-columnFilter type="date" field="TargetDate" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateCompleted">
				<div class="table-header-label">
					Completed<p-sortIcon field="DateCompleted"></p-sortIcon>
					<p-columnFilter type="date" field="DateCompleted" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th *ngIf="showLink()" style="width: 3rem"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-poDetail>
		<tr class="po-detail-row" data-testid="relatedPORow">
			<td>
				<span class="is-completed-style"> <i class="fa fa-circle dot-{{ poDetail.RecordTypeCode?.toLowerCase() }}"></i></span> {{ poDetail.ReferenceName }}
			</td>
			<td>
				<span class="is-completed-style"> <i class="fa fa-circle dot-wo"></i></span> {{ poDetail.WorkOrderNumber }}
			</td>
			<td>
				<span><i class="fa fa-circle dot-{{ poDetail.AssetRecordTypeCode?.toLowerCase() }}"></i></span> {{ poDetail.AssetNumber }}
			</td>
			<td>{{ poDetail.AssetReference }}</td>
			<td>{{ poDetail.Street }}</td>
			<td>{{ poDetail.Suburb }}</td>
			<td>{{ poDetail.BusinessEntityName }}</td>
			<td>{{ poDetail.StatusName }}</td>

			<td>
				<span [innerHTML]="poDetail.ScheduledStartDate | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td>
				<span [innerHTML]="poDetail.TargetDate | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td>
				<span [innerHTML]="poDetail.DateCompleted | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td *ngIf="showLink()" style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					*ngIf="canNavigateToDetails(poDetail)"
					type="button"
					pButton
					pRipple
					pTooltip="Click For {{ getRowTypeName(poDetail) ?? poDetail.ReferenceTypeCode }}"
					tooltipPosition="bottom"
					(click)="handleRowClick(poDetail)"
					class="p-button-text p-button-rounded p-button-plain"
					icon="pi pi-link"></button>
				<div
					*ngIf="!canNavigateToDetails(poDetail)"
					class="p-element p-button-text p-button-rounded p-button-plain p-button p-component p-button-icon-only ng-star-inserted"></div>
			</td>
		</tr>
	</ng-template>
</p-table>
