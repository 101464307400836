export class DataRestrictionsRequest {
	AssetNumber: string;
	AssetTypeCode: string;
	ClientId: string;
	CompanyId: string;
	ServiceAreaId: string;
	ServiceProjectId: string;

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
