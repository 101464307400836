import { CodeValue, StatusLookupValue } from '.';

export class DataRestrictionsLookupValue extends StatusLookupValue {
	CompanyRestrictions: CodeValue[];
	ClientRestrictions: CodeValue[];
	ServiceAreaRestrictions: CodeValue[];
	ServiceProjectRestrictions: CodeValue[];
	AssetRestrictions: CodeValue[];
	AssetTypeRestrictions: CodeValue[];

	EmailAddress: string;
}
