<div class="container">
	<div class="grid">
		<div class="mt-0">
			<div class="lock mt-5 pt-5"></div>
			<div class="message">
				<h1>Access to this page is restricted</h1>
				<p class="flex justify-content-center">We are sorry, but you do not have access to this page or resource</p>
			</div>
		</div>
	</div>
</div>
