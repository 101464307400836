<p-toolbar *ngIf="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)">
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="button-with-tooltip-holder" *ngIf="showSendInvitation() && formMode == formModes.View" pTooltip="Send Invitation" tooltipPosition="bottom">
			<p-button icon="pi pi-send" label="Send Invitation" (onClick)="sendInvitation()"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="showEdit() && formMode == formModes.View" [pTooltip]="getEditTooltip()" tooltipPosition="bottom">
			<p-button icon="pi pi-pencil" [disabled]="!allowEdit()" data-testid="userProfileDetailsEdit" label="Edit" (onClick)="editDetails()"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.Edit, formModes.Create].includes(formMode)" pTooltip="Save User Profile" tooltipPosition="bottom">
			<p-button icon="pi pi-save" label="Save" (onClick)="saveDetails()"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="formMode == formModes.Edit" pTooltip="Cancel User Profile" tooltipPosition="bottom">
			<p-button icon="pi pi-ban" label="Cancel" (onClick)="cancelDetailsEdit()"> </p-button>
		</div>
	</ng-template>
</p-toolbar>
<form [formGroup]="detailForm">
	<div class="grid formgrid m-0 p-0">
		<!-- row -->

		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="detailForm.get('username')"
				label="Username">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="detailForm.get('displayName')"
				label="Display Name">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Edit].includes(formMode) && isCurrentUser == false"
				[controlType]="formControls.Autocomplete"
				[control]="detailForm.get('status')"
				[lookupValues]="statuses"
				label="Status">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Edit].includes(formMode) && isCurrentUser == false"
				[controlType]="formControls.Autocomplete"
				[control]="detailForm.get('userRole')"
				[lookupValues]="userRoles"
				label="User Role"
				data-testid="userProfileDetailsUserRoles">
			</app-form-item>
		</div>

		<!-- row -->

		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="detailForm.get('contactPhone')"
				label="Phone #">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="detailForm.get('contactMobile')"
				label="Mobile #">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="detailForm.get('contactEmail')"
				label="E-mail">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element"></div>

		<!-- row -->

		<div class="col-3 md:col-0 form-element"></div>
		<div class="col-3 md:col-0 form-element"></div>
		<div class="col-3 md:col-0 form-element"></div>
		<div class="col-3 md:col-0 form-element"></div>

		<!-- row -->

		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="detailForm.get('addedBy')"
				label="Created by">
			</app-form-item>
		</div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Calendar"
				[control]="detailForm.get('dateAdded')"
				label="Created">
			</app-form-item>
		</div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="detailForm.get('updatedBy')"
				label="Last Updated by">
			</app-form-item>
		</div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Calendar"
				[control]="detailForm.get('dateUpdated')"
				label="Last Updated">
			</app-form-item>
		</div>
	</div>
</form>
