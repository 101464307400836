<p-toolbar *ngIf="showList">
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="button-with-tooltip-holder" *ngIf="showAddRecord()" [pTooltip]="getAddTooltip()" tooltipPosition="bottom">
			<p-button data-testid="entityInsuranceAddButton" [disabled]="!allowAddRecord()" icon="pi pi-plus" label="Add" (onClick)="addClick($event)"></p-button>
		</div>
	</ng-template>
</p-toolbar>
<p-table
	*ngIf="showList"
	[value]="records"
	dataKey="BusinessEntityInsuranceId"
	[rowHover]="true"
	responsiveLayout="scroll"
	[showCurrentPageReport]="true"
	[loading]="loading"
	[filterDelay]="0"
	#recordsTable>
	<ng-template pTemplate="header">
		<tr>
			<th pSortableColumn="InsuranceTypeName">
				<div class="table-header-label">
					Insurance Type
					<p-sortIcon field="InsuranceTypeName"></p-sortIcon>
					<p-columnFilter type="text" field="InsuranceTypeName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="PolicyNumber">
				<div class="table-header-label">
					Policy #
					<p-sortIcon field="PolicyNumber"></p-sortIcon>
					<p-columnFilter type="text" field="PolicyNumber" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="CoverAmount">
				<div class="table-header-label">
					Cover Amount
					<p-sortIcon field="CoverAmount"></p-sortIcon>
					<p-columnFilter type="text" field="CoverAmount" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="ExpiryDate">
				<div class="table-header-label">
					Expiry
					<p-sortIcon field="ExpiryDate"></p-sortIcon>
					<p-columnFilter type="date" field="ExpiryDate" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="Comments">
				<div class="table-header-label">
					Comments
					<p-sortIcon field="Comments"></p-sortIcon>
					<p-columnFilter type="text" field="Comments" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th style="width: 3rem"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-item>
		<tr class="p-selectable-row">
			<td>
				{{ item.InsuranceTypeName }}
			</td>
			<td>
				{{ item.PolicyNumber }}
			</td>
			<td>
				{{ item.CoverAmount }}
			</td>
			<td *ngIf="!item.ExpiryDateNotApplicable && !item.ExpiryDate"></td>
			<td *ngIf="item.ExpiryDateNotApplicable">n/a</td>
			<td *ngIf="item.ExpiryDate">
				<span [innerHTML]="item.ExpiryDate | formatDate : region.RegionCode : region.DateFormat"></span>
			</td>
			<td>
				{{ item.Comments }}
			</td>
			<td style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					data-testid="entityInsuranceExpandButton"
					type="button"
					pButton
					pRipple
					pTooltip="Expand"
					tooltipPosition="bottom"
					(click)="expandClick(item)"
					class="p-button-text p-button-rounded p-button-plain"
					[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
			</td>
		</tr>
	</ng-template>
</p-table>
<div *ngIf="showList" class="custom-pagination">
	<span>Showing {{ records == null ? 0 : records.length }} entries</span>
</div>
<app-entity-insurance-detail
	*ngIf="!showList"
	[record]="selectedRecord"
	(editFinished)="editFinished()"
	(editCancelled)="editCancelled()"
	(detailsClosed)="detailsClosed()">
</app-entity-insurance-detail>
