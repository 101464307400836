export class ViewByFilter {
	AssetNumbers: string[];
	AssetTypeCodes: string[];
	ClientIds: string[];
	CompanyIds: string[];
	ServiceAreaIds: string[];
	ServiceProjectIds: string[];

	constructor(properties?: any) {
		if (properties) {
			for (const key in properties) {
				this[key] = properties[key];
			}
		}
	}
}
