import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthComponent } from './core/components/auth/auth.component';
import { AccessDeniedComponent } from './features/access-denied/access-denied.component';
import { UserProfileComponent } from './features/account-admin/user/pages/user-profile/user-profile.component';
import { UserProfileResolver } from './features/account-admin/user/pages/user-profile/user-profile.resolver';
import { AppMainComponent } from './layout/main/app.main.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
	{
		/**
		 * Needed for login on page load for PathLocationStrategy.
		 * See FAQ for details:
		 * https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/FAQ.md
		 */
		path: 'auth',
		component: AuthComponent,
	},
	{
		path: '',
		component: AppMainComponent,
		runGuardsAndResolvers: 'always',
		/**
		 * MSAL Angular can protect routes in your application
		 * using MsalGuard. For more info, visit:
		 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#secure-the-routes-in-your-application
		 */
		canActivate: [MsalGuard],

		children: [
			{ path: 'dashboard', loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule) },
			{
				path: 'service-request',
				loadChildren: () => import('./features/service-request/service-request.module').then(m => m.ServiceRequestModule),
			},
			{
				path: 'scope',
				loadChildren: () => import('./features/scope/scope.module').then(m => m.ScopeModule),
			},
			{
				path: 'quote',
				loadChildren: () => import('./features/quote/quote.module').then(m => m.QuoteModule),
			},
			{
				path: 'order',
				loadChildren: () => import('./features/order/order.module').then(m => m.OrderModule),
			},
			{
				path: 'schedule',
				loadChildren: () => import('./features/schedule/schedule.module').then(m => m.ScheduleModule),
			},
			{
				path: 'account-admin',
				loadChildren: () => import('./features/account-admin/account-admin.module').then(m => m.AccountAdminModule),
			},
			{
				path: 'setting',
				loadChildren: () => import('./features/setting/setting.module').then(m => m.SettingModule),
			},
			{
				path: 'rate',
				loadChildren: () => import('./features/rate/rate.module').then(m => m.RateModule),
			},
			{
				path: 'contact',
				loadChildren: () => import('./features/contact/contact.module').then(m => m.ContactModule),
			},
			{
				path: 'asset',
				loadChildren: () => import('./features/asset/asset.module').then(m => m.AssetModule),
				data: { moduleName: 'Asset' },
			},
			{
				path: 'property',
				loadChildren: () => import('./features/property/property.module').then(m => m.PropertyModule),
				data: { moduleName: 'Property' },
			},
			{
				path: 'invoice',
				loadChildren: () => import('./features/invoice/invoice.module').then(m => m.InvoiceModule),
			},
			{
				path: 'calendar',
				loadChildren: () => import('./features/calendar/calendar.module').then(m => m.CalendarModule),
			},
			{ path: 'report', loadChildren: () => import('./features/report/report.module').then(m => m.ReportModule) },
			{
				path: 'profile',
				data: { myProfile: true },
				component: UserProfileComponent,
				resolve: { pageData: UserProfileResolver },
			},
			{
				path: 'system-interface',
				loadChildren: () => import('./features/system-interface/system-interface.module').then(m => m.SystemInterfaceModule),
			},
			{
				path: 'dev-tool',
				loadChildren: () => import('./features/dev-tool/dev-tool.module').then(m => m.DevToolModule),
			},
			{
				path: 'subscription',
				loadChildren: () => import('./features/subscription/subscription.module').then(m => m.SubscriptionModule),
			},
			{ path: 'access-denied', component: AccessDeniedComponent },
			{ path: '', redirectTo: '/dashboard', pathMatch: 'full' },
			{ path: '**', component: PageNotFoundComponent },
		],
	},
	{ path: '**', component: PageNotFoundComponent },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			enableTracing: false,
			onSameUrlNavigation: 'reload',
			// Don't perform initial navigation in iframes
			initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
