import { Constants } from './constants.constant';

export enum RelatedTabEnum {
	RateGroup = 'RATE_GROUP',
	Schedules = 'SCHEDULES',
	WorkHistory = 'WORK_HISTORY',
	Flow = 'FLOW',
	ScheduleHistory = 'SCHEDULE_HISTORY',
	WorkOrders = 'WORK_ORDERS',
	PurchaseOrders = 'PURCHASE_ORDERS',
	Employees = 'EMPLOYEES',
}
export class RelatedTabConstants {
	static RELATED_TAB_DEFAULT_FILTER_LIST = [{ value: RelatedTabEnum.RateGroup, label: 'RATE GROUPS', icon: '' }];

	static RELATED_TAB_CLIENT_DISPLAY_FILTER_LIST = [
		{ value: RelatedTabEnum.Schedules, label: 'SCHEDULES', icon: '' },
		{ value: RelatedTabEnum.RateGroup, label: 'RATE GROUPS', icon: '' },
		{ value: RelatedTabEnum.WorkHistory, label: 'WORK HISTORY', icon: '' },
	];

	static RELATED_TAB_SRQ_DISPLAY_FILTER_LIST = [
		{ value: RelatedTabEnum.Flow, label: 'FLOW', icon: '' },
		{ value: RelatedTabEnum.WorkHistory, label: 'WORK HISTORY', icon: '' },
	];

	static RELATED_TAB_SCOPE_DISPLAY_FILTER_LIST = [
		{ value: RelatedTabEnum.Flow, label: 'FLOW', icon: '' },
		{ value: RelatedTabEnum.WorkHistory, label: 'WORK HISTORY', icon: '' },
	];

	static RELATED_TAB_QUOTE_DISPLAY_FILTER_LIST = [
		{ value: RelatedTabEnum.Flow, label: 'FLOW', icon: '' },
		{ value: RelatedTabEnum.WorkHistory, label: 'WORK HISTORY', icon: '' },
	];

	static RELATED_TAB_WORK_ORDER_DISPLAY_FILTER_LIST = [
		{ value: RelatedTabEnum.Flow, label: 'FLOW', icon: '' },
		{ value: RelatedTabEnum.ScheduleHistory, label: 'SCHEDULE HISTORY', icon: '' },
		{ value: RelatedTabEnum.WorkHistory, label: 'WORK HISTORY', icon: '' },
	];

	static RELATED_TAB_PURCHASE_ORDER_DISPLAY_FILTER_LIST = [
		{ value: RelatedTabEnum.Flow, label: 'FLOW', icon: '' },
		{ value: RelatedTabEnum.ScheduleHistory, label: 'SCHEDULE HISTORY', icon: '' },
		{ value: RelatedTabEnum.WorkHistory, label: 'WORK HISTORY', icon: '' },
	];

	static RELATED_TAB_WO_SCHEDULE_DISPLAY_FILTER_LIST = [
		{ value: RelatedTabEnum.WorkOrders, label: 'WORK ORDERS', icon: '' },
		{ value: RelatedTabEnum.PurchaseOrders, label: 'PURCHASE ORDERS', icon: '' },
	];

	static RELATED_TAB_INVOICE_SCHEDULE_DISPLAY_FILTER_LIST = [
		{ value: RelatedTabEnum.Flow, label: 'FLOW', icon: '' },
		{ value: RelatedTabEnum.WorkHistory, label: 'WORK HISTORY', icon: '' },
	];

	static RELATED_TAB_ASSET_DISPLAY_FILTER_LIST = [
		{ value: RelatedTabEnum.Schedules, label: 'SCHEDULES', icon: '' },
		{ value: RelatedTabEnum.ScheduleHistory, label: 'SCHEDULE HISTORY', icon: '' },
		{ value: RelatedTabEnum.WorkHistory, label: 'WORK HISTORY', icon: '' },
	];

	static readonly FILTER_LIST = [
		{
			TabName: 'FLOW',
			TabValue: RelatedTabEnum.Flow,
			permission: null,
		},
		{
			TabName: 'SCHEDULES',
			TabValue: RelatedTabEnum.Schedules,
			permission: Constants.UserPermission.OrderScheduleView,
		},
		{
			TabName: 'SCHEDULE HISTORY',
			TabValue: RelatedTabEnum.ScheduleHistory,
			permission: Constants.UserPermission.WorkOrderView,
		},
		{
			TabName: 'WORK ORDERS',
			TabValue: RelatedTabEnum.WorkOrders,
			permission: Constants.UserPermission.WorkOrderView,
		},
		{
			TabName: 'PURCHASE ORDERS',
			TabValue: RelatedTabEnum.PurchaseOrders,
			permission: Constants.UserPermission.PurchaseOrderView,
		},
		{
			TabName: 'RATE GROUPS',
			TabValue: RelatedTabEnum.RateGroup,
			permission: Constants.UserPermission.SorView,
		},
		{
			TabName: 'WORK HISTORY',
			TabValue: RelatedTabEnum.WorkHistory,
			permission: null,
		},
		{
			TabName: 'EMPLOYEES',
			TabValue: RelatedTabEnum.Employees,
			permission: null,
		},
	];

	static DEFAULT_RELATED_TAB_NONE = 'NONE';
}
