<p-toolbar>
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="button-with-tooltip-holder" *ngIf="showEdit() && [formModes.View].includes(formMode)" [pTooltip]="getDeleteTooltip()" tooltipPosition="bottom">
			<p-button label="Delete" (onClick)="deleteRecord()" icon="pi pi-trash" data-testid="entityLicenceDetailsDelete" [disabled]="!allowDelete()"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.Create, formModes.Edit].includes(formMode)" pTooltip="Save Licence" tooltipPosition="bottom">
			<p-button label="Save" (onClick)="saveRecord()" icon="pi pi-save"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="showEdit() && [formModes.View].includes(formMode)" [pTooltip]="getEditTooltip()" tooltipPosition="bottom">
			<p-button label="Edit" (onClick)="editRecord()" icon="pi pi-pencil" data-testid="entityLicenceDetailsEdit" [disabled]="!allowEdit()"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.Edit].includes(formMode)" pTooltip="Cancel Edit" tooltipPosition="bottom">
			<p-button label="Cancel" (onClick)="cancelRecordEdit()" icon="pi pi-ban"> </p-button>
		</div>
		<div
			class="button-with-tooltip-holder"
			*ngIf="[formModes.Create, formModes.View].includes(formMode)"
			pTooltip="Back To Licences List"
			tooltipPosition="bottom">
			<p-button label="Back" (onClick)="backToList()" icon="pi pi-arrow-left"> </p-button>
		</div>
	</ng-template>
</p-toolbar>
<form [formGroup]="recordForm">
	<div class="grid formgrid m-0 p-0">
		<div *ngIf="fixedLicenceHolderName == null" class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="recordForm.get('licenceHolderName')"
				label="Licence Holder Name">
			</app-form-item>
		</div>
		<div *ngIf="fixedLicenceHolderName == null" class="col-3 md:col-0 form-element"></div>
		<div *ngIf="fixedLicenceHolderName == null" class="col-3 md:col-0 form-element"></div>
		<div *ngIf="fixedLicenceHolderName == null" class="col-3 md:col-0 form-element"></div>

		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Autocomplete"
				[lookupValues]="workServiceTypes"
				[control]="recordForm.get('workServiceType')"
				label="Work / Service Types">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Autocomplete"
				[lookupValues]="licenceCertificateTypes"
				[control]="recordForm.get('licenceCertificateType')"
				label="Licence / Certificate Type">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="recordForm.get('licenceCertificateNumber')"
				label="Licence / Certificate #">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element"></div>

		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.CalendarDate"
				[control]="recordForm.get('expiryDate')"
				(inputChange)="expiryChanged()"
				label="Expiry">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.Create, formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Create, formModes.Edit].includes(formMode)"
				[controlType]="formControls.CheckBox"
				[control]="recordForm.get('expiryDateNotApplicable')"
				(inputChange)="expiryChanged()"
				label="Expiry n/a?">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element"></div>
		<div class="col-3 md:col-0 form-element"></div>

		<!-- blank -->
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element"></div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element"></div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element"></div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element"></div>

		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="recordForm.get('addedBy')"
				label="Created by">
			</app-form-item>
		</div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Textbox"
				[control]="recordForm.get('updatedBy')"
				label="Last Updated by">
			</app-form-item>
		</div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element"></div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element"></div>

		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Calendar"
				[control]="recordForm.get('dateAdded')"
				label="Created">
			</app-form-item>
		</div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Calendar"
				[control]="recordForm.get('dateUpdated')"
				label="Last Updated">
			</app-form-item>
		</div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="[formModes.Readonly].includes(formMode)"
				[controlType]="formControls.Calendar"
				[control]="recordForm.get('dateRemoved')"
				label="Deleted">
			</app-form-item>
		</div>
		<div *ngIf="[formModes.Edit, formModes.View].includes(formMode)" class="col-3 md:col-0 form-element"></div>
	</div>
</form>
