import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { AppContextService, SecurityService } from '.';
import { UserNotificationEvent } from '../models';

@Injectable({
	providedIn: 'root',
})
export class SignalRService {
	private hubConnection: signalR.HubConnection;

	calendarEventUpdated = new EventEmitter();
	notificationTest = new EventEmitter();
	userUpdated = new EventEmitter();

	constructor(private appContext: AppContextService, private securityService: SecurityService) {
		this.hubConnection = new signalR.HubConnectionBuilder()
			.configureLogging(environment.signalRLogging)
			.withUrl(environment.signalRNotificationHub)
			.withAutomaticReconnect({
				nextRetryDelayInMilliseconds: retryContext => {
					// if <60 seconds, retry after 5, otherwise retry every minute
					if (retryContext.elapsedMilliseconds < 60000) {
						return 5000; // 5 seconds
					} else {
						return 60000;
					}
				},
			})
			.build();

		this.hubConnection.onreconnected(connectionId => this.initialiseConnection());

		this.connect();
	}

	private connect() {
		if (this.hubConnection.state !== signalR.HubConnectionState.Connected) {
			this.hubConnection
				.start()
				.then(() => this.initialiseConnection())
				.catch(err => console.log('Error while starting connection: ' + err));
		}
	}

	private initialiseConnection() {
		this.joinGroup(`User-${this.appContext.user.UserProfileId}`);
		this.joinGroup(`Subscriber-${this.appContext.user.SubscriberId}`);

		this.hubConnection.off('UserNotification');
		this.hubConnection.on('UserNotification', (dto: UserNotificationEvent) => {
			this.processUserNotificationEvent(dto);
		});
	}

	private processUserNotificationEvent(dto: UserNotificationEvent) {
		switch (dto.NotificationQueueActionCode) {
			case Constants.NotificationQueueAction.NotificationTest:
				return this.processNotificationTestEvent(dto);
			case Constants.NotificationQueueAction.SendSystemMessage:
				return this.processSystemMessage(dto);
			case Constants.NotificationQueueAction.SubscriberChanged:
				return this.processSubscriberChangedEvent(dto);
			case Constants.NotificationQueueAction.UserChanged:
				return this.processUserChangedEvent(dto);
			case Constants.NotificationQueueAction.UserRoleChanged:
				return this.processUserRoleChangedEvent(dto);
			case Constants.NotificationQueueAction.CalendarChanged:
				return this.processCalendarEventChangedEvent(dto);
		}
	}

	public processUserChangedEvent(dto: UserNotificationEvent) {
		this.securityService.updateCurrentUser(true).subscribe(x => {
			this.userUpdated.emit();
		});
	}

	public processUserRoleChangedEvent(dto: UserNotificationEvent) {
		if (this.appContext.user?.UserRoleId == dto.Data) this.securityService.updateCurrentUser(true).subscribe();
	}

	public processSubscriberChangedEvent(dto: UserNotificationEvent) {
		this.securityService.updateCurrentUser(true).subscribe();
	}

	public processCalendarEventChangedEvent(dto: UserNotificationEvent) {
		if (dto.UserSessionId != this.appContext.user?.UserSessionId) this.calendarEventUpdated.emit(dto.Data);
	}

	private processNotificationTestEvent(dto: UserNotificationEvent) {
		this.notificationTest.emit(dto.Data); // always send
	}

	private processSystemMessage(dto: UserNotificationEvent) {
		this.appContext.util.showActionResult(dto.Data);
	}

	//i need join group to listen to the server for messages
	public joinGroup(groupName: string) {
		this.hubConnection.invoke('JoinGroup', groupName);
	}
}
