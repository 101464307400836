<p-toolbar>
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div
			class="button-with-tooltip-holder"
			*ngIf="showDelete() && [formModes.View].includes(formMode)"
			[pTooltip]="getDeleteTooltip()"
			tooltipPosition="bottom">
			<p-button icon="pi pi-trash" [disabled]="!allowDelete()" (onClick)="deleteItem()" label="Delete" data-testid="notesDetailDeleteButton"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="showEdit() && [formModes.View].includes(formMode)" [pTooltip]="getEditTooltip()" tooltipPosition="bottom">
			<p-button label="Edit" [disabled]="!allowEdit()" (onClick)="editItem()" icon="pi pi-pencil" data-testid="notesDetailEditButton"> </p-button>
		</div>
		<div class="button-with-tooltip-holder" *ngIf="[formModes.Edit, formModes.Create].includes(formMode)" pTooltip="Save Note" tooltipPosition="bottom">
			<p-button label="Save" (onClick)="saveItem()" icon="pi pi-save" data-testid="notesDetailSaveButton"> </p-button>
		</div>
		<div
			class="button-with-tooltip-holder"
			*ngIf="[formModes.Edit, formModes.Create].includes(formMode) && openedInEdit == false"
			pTooltip="Cancel Edit"
			tooltipPosition="bottom">
			<p-button label="Cancel" (onClick)="cancelItemEdit()" icon="pi pi-ban"> </p-button>
		</div>
		<div
			class="button-with-tooltip-holder"
			*ngIf="[formModes.Create].includes(formMode) || openedInEdit"
			pTooltip="Cancel Edit and Back To Notes List"
			tooltipPosition="bottom">
			<p-button label="Cancel" (onClick)="backToList()" icon="pi pi-ban"> </p-button>
		</div>
		<div
			class="button-with-tooltip-holder"
			*ngIf="[formModes.View].includes(formMode) && openedInEdit == false"
			pTooltip="Back To Notes List"
			tooltipPosition="bottom">
			<p-button label="Back" (onClick)="backToList()" icon="pi pi-arrow-left" data-testid="notesDetailBackToNoteListButton"> </p-button>
		</div>
	</ng-template>
</p-toolbar>
<form [formGroup]="noteDetailForm">
	<div class="grid formgrid m-0 p-0">
		<!-- row -->
		<div class="col-3 md:col-0 form-element" *ngIf="showReferenceColumn">
			<app-form-item [visible]="true" [editable]="false" [controlType]="formControls.Textbox" [control]="noteDetailForm.get('referenceName')" label="Reference">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)"
				[editable]="[formModes.Edit, formModes.Create].includes(formMode)"
				[controlType]="formControls.Autocomplete"
				[control]="noteDetailForm.get('noteCategory')"
				[lookupValues]="noteCategories"
				label="Category">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)"
				[editable]="[formModes.Edit, formModes.Create].includes(formMode)"
				[controlType]="formControls.Autocomplete"
				[control]="noteDetailForm.get('noteAlertType')"
				[lookupValues]="noteAlertTypes"
				label="Alert Type">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element"></div>
		<div class="col-3 md:col-0 form-element" *ngIf="!showReferenceColumn"></div>

		<!-- row -->

		<div class="col-6 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit, formModes.Create].includes(formMode)"
				[editable]="[formModes.Edit, formModes.Create].includes(formMode)"
				[controlType]="formControls.Textarea"
				[control]="noteDetailForm.get('comments')"
				label="Comments"
				data-testid="notesDetailComments">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element"></div>
		<div class="col-3 md:col-0 form-element"></div>

		<!-- row -->

		<div class="col-12 md:col-0 form-element"></div>

		<!-- row -->

		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="false"
				[controlType]="formControls.Textbox"
				[control]="noteDetailForm.get('addedBy')"
				[iconAfterTextboxReadOnly]="getUserRelationTypeIcon(item?.AddedByExternalUserRelationTypeCode)"
				[iconAfterTextboxTooltip]="getUserRelationTypeTooltip(item?.AddedByExternalUserRelationTypeCode)"
				label="Created by">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="false"
				[controlType]="formControls.Calendar"
				[control]="noteDetailForm.get('dateAdded')"
				label="Created">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="false"
				[controlType]="formControls.Textbox"
				[control]="noteDetailForm.get('updatedBy')"
				[iconAfterTextboxReadOnly]="getUserRelationTypeIcon(item?.UpdatedByExternalUserRelationTypeCode)"
				[iconAfterTextboxTooltip]="getUserRelationTypeTooltip(item?.UpdatedByExternalUserRelationTypeCode)"
				label="Last Updated by">
			</app-form-item>
		</div>
		<div class="col-3 md:col-0 form-element">
			<app-form-item
				[visible]="[formModes.View, formModes.Edit].includes(formMode)"
				[editable]="false"
				[controlType]="formControls.Calendar"
				[control]="noteDetailForm.get('dateUpdated')"
				label="Last Updated">
			</app-form-item>
		</div>

		<!-- row -->

		<div class="col-3 md:col-0 form-element"></div>
		<div class="col-3 md:col-0 form-element"></div>
		<div class="col-3 md:col-0 form-element"></div>
		<div class="col-3 md:col-0 form-element" *ngIf="noteDetailForm.get('dateRemoved').value">
			<app-form-item [controlType]="formControls.Calendar" [control]="noteDetailForm.get('dateRemoved')" label="Deleted"> </app-form-item>
		</div>
	</div>
</form>
