<p-table
	#relatedFlowhistoryTable
	*ngIf="showList"
	[value]="relatedFlowHistory"
	dataKey="data"
	[rowHover]="true"
	responsiveLayout="scroll"
	[showCurrentPageReport]="true"
	[loading]="loading"
	[filterDelay]="0">
	<ng-template pTemplate="header">
		<tr class="flow-history-table-row">
			<th pSortableColumn="FlowAction" data-testid="flowHistoryFlowed">
				<div class="table-header-label">
					Flowed<p-sortIcon field="FlowAction"> </p-sortIcon>
					<p-columnFilter type="text" field="FlowAction" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="ReferenceTypeName" data-testid="flowHistoryType">
				<div class="table-header-label">
					Type<p-sortIcon field="ReferenceTypeName"> </p-sortIcon>
					<p-columnFilter type="text" field="ReferenceTypeName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="ObjectNumber" data-testid="flowHistoryReference">
				<div class="table-header-label">
					Reference<p-sortIcon field="ObjectNumber"> </p-sortIcon>
					<p-columnFilter type="text" field="ObjectNumber" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="TargetDate" data-testid="flowHistoryDue">
				<div class="table-header-label">
					Due<p-sortIcon field="TargetDate"></p-sortIcon>
					<p-columnFilter type="date" field="TargetDate" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateCompleted" data-testid="flowHistoryCompleted">
				<div class="table-header-label">
					Completed<p-sortIcon field="DateCompleted"></p-sortIcon>
					<p-columnFilter type="date" field="DateCompleted" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="StatusName" data-testid="flowHistoryStatus">
				<div class="table-header-label">
					Status<p-sortIcon field="StatusName"></p-sortIcon>
					<p-columnFilter type="text" field="StatusName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateAdded" data-testid="flowHistoryCreated">
				<div class="table-header-label">
					Created<p-sortIcon field="DateAdded"></p-sortIcon>
					<p-columnFilter type="date" field="DateAdded" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th *ngIf="showLink()" style="width: 3rem"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-relatedFlowHistory>
		<tr class="flow-history-table-row">
			<td>{{ relatedFlowHistory.FlowAction }}</td>
			<td>{{ relatedFlowHistory.LinkedObjectName ?? relatedFlowHistory.ReferenceTypeName }}</td>
			<td>
				<span *ngIf="relatedFlowHistory.RecordTypeCode" class="is-completed-style">
					<i class="fa fa-circle dot-{{ relatedFlowHistory.RecordTypeCode.toLowerCase() }}"></i>
				</span>
				{{ relatedFlowHistory.ObjectNumber }}
			</td>
			<td><span [innerHTML]="relatedFlowHistory.TargetDate | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span></td>
			<td><span [innerHTML]="relatedFlowHistory.DateCompleted | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span></td>
			<td>{{ relatedFlowHistory.StatusName }}</td>
			<td><span [innerHTML]="relatedFlowHistory.DateAdded | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span></td>
			<td *ngIf="showLink()" style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					*ngIf="canNavigateToDetails(relatedFlowHistory)"
					type="button"
					pButton
					pRipple
					pTooltip="Click For {{ relatedFlowHistory.LinkedObjectName ?? relatedFlowHistory.ReferenceTypeName }}"
					tooltipPosition="bottom"
					(click)="handleRowClick(relatedFlowHistory)"
					class="p-button-text p-button-rounded p-button-plain"
					icon="pi pi-link"></button>
				<div
					*ngIf="!canNavigateToDetails(relatedFlowHistory)"
					class="p-element p-button-text p-button-rounded p-button-plain p-button p-component p-button-icon-only ng-star-inserted"></div>
			</td>
		</tr>
	</ng-template>
</p-table>
<div *ngIf="showList" class="custom-pagination">
	<span>Showing {{ relatedFlowHistory == null ? 0 : relatedFlowHistory.length }} entries</span>
</div>
