<div class="grid">
	<div class="col-12 pr-0 pl-0 pb-1">
		<p-toolbar *ngIf="myProfile != true">
			<ng-template pTemplate="left"></ng-template>
			<ng-template pTemplate="right">
				<div class="button-with-tooltip-holder" pTooltip="Back To User List" tooltipPosition="bottom">
					<p-button label="Back To List" icon="pi pi-arrow-left" (onClick)="goBackToListPage()"> </p-button>
				</div>
			</ng-template>
		</p-toolbar>
	</div>

	<div class="col-12 pt-0 pb-0">
		<p-tabView #tabs (onChange)="tabOnChange($event)">
			<p-tabPanel header="Details">
				<app-user-profile-details #detail [item]="item" [formMode]="formMode" (detailsUpdated)="detailsUpdated($event)" (editCancelled)="editCancelled()">
				</app-user-profile-details>
			</p-tabPanel>
			<p-tabPanel *ngIf="myProfile != true && showActivity()" header="Activity">
				<app-audit-history
					#auditHistoryList
					[showActionsToolbar]="false"
					[parentReferenceId]="item.UserProfileId"
					[parentReferenceTypeCode]="referenceTypeCode">
				</app-audit-history>
			</p-tabPanel>
		</p-tabView>
	</div>
</div>
