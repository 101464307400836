import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
	AssetLookupValue,
	BusinessEntityLookupValue,
	CalendarResourceLookupValue,
	CompanyLookupValue,
	ContactLookupValue,
	FinancialEntityLookupValue,
	InvoiceLookupValue,
	InvoiceTargetLookupValue,
	OrderLookupValue,
	RecordLookupValue,
	SorLookupValue,
	StatusLookupValue,
	TaxLookupValue,
	UserAllocationLookupValue,
	UserProfileLookupValue,
} from 'src/app/shared/models/lookups';
import { GroupLookupValue, LookupValue } from 'src/app/shared/models/lookups';
import { DataRestrictionsRequest } from 'src/app/shared/models/shared';
import { ApiService } from './api.service';
import { AppContextService } from './app-context.service';
import { _LookupPartialService } from './lookup.partial.service';
import { UtilService } from './util.service';

@Injectable({
	providedIn: 'root',
})
export class LookupService extends _LookupPartialService {
	constructor(apiService: ApiService, appContext: AppContextService, private util: UtilService) {
		super(apiService, appContext);
	}

	// #region -- Generic Lookups --

	getAttachmentTypes(parentReferenceTypeCode: string): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetAttachmentTypes/${parentReferenceTypeCode}`);
	}

	getDefaultReviewResultAttachmentType(parentReferenceTypeCode: string): Observable<LookupValue> {
		return this.apiService.get<LookupValue>(`/Lookup/GetDefaultReviewResultAttachmentType/${parentReferenceTypeCode}`);
	}

	getNoteCategories(referenceTypeCode: string): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetNoteCategories/${referenceTypeCode}`);
	}

	getInsuranceTypesForRequired(isRequired: boolean, activeOnly = true): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetInsuranceTypesForRequired/${isRequired}/${activeOnly}`);
	}

	getContactTypesForReference(referenceTypeCode: string, activeOnly = true): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetContactTypesForReference/${referenceTypeCode}/${activeOnly}`);
	}

	getRecordByKeywords(keywords: string, refTypes: string): Observable<RecordLookupValue[]> {
		let httpParams: HttpParams = new HttpParams();
		if (keywords) httpParams = httpParams.append('keywords', keywords);
		if (refTypes) httpParams = httpParams.append('recordTypeCodes', refTypes);
		return this.apiService.get<RecordLookupValue[]>('/Lookup/GetRecordByKeywords', httpParams);
	}

	getRecord(refType: string, refId: string | null): Observable<RecordLookupValue> {
		let httpParams: HttpParams = new HttpParams();
		if (refType) httpParams = httpParams.append('recordTypeCode', refType);
		if (refId) httpParams = httpParams.append('recordId', refId);

		return this.apiService.get<RecordLookupValue>(`/Lookup/GetRecord/`, httpParams);
	}

	// #endregion

	// #region -- Config Lookups --

	getAssetsByKeywords(keywords: string, maxRecords: number): Observable<AssetLookupValue[]> {
		let httpParams: HttpParams = new HttpParams().append('maxRecords', maxRecords);
		if (keywords) httpParams = httpParams.append('keywords', keywords);

		return this.apiService.get<AssetLookupValue[]>('/Lookup/GetAssetsByKeywords', httpParams).pipe(
			map(assets => {
				assets.forEach(asset => {
					asset.NameValue = asset.AdditionalDisplayValue;
				});
				return assets;
			})
		);
	}

	getAssetTypesForAsset(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetAssetTypesForAsset', httpParams);
	}

	getAssetTypesForProperty(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetAssetTypesForProperty', httpParams);
	}

	getSorsByKeywords(keywords: string, rateGroupId?: string, maxRecords = 20, searchAll = true): Observable<SorLookupValue[]> {
		let httpParams: HttpParams = new HttpParams().append('maxRecords', maxRecords);
		if (keywords) httpParams = httpParams.append('keywords', keywords);
		if (rateGroupId) httpParams = httpParams.append('rateGroupId', rateGroupId);
		if (searchAll) httpParams = httpParams.append('searchAll', true);

		return this.apiService.get<SorLookupValue[]>('/Lookup/GetSorsByKeywords', httpParams);
	}

	getSorBySorId(sorId: string, activeOnly = true): Observable<SorLookupValue> {
		if (!sorId) return of(null);

		return this.apiService.get<SorLookupValue>(`/Lookup/GetSorBySorId/${sorId}/${activeOnly}`);
	}

	getProblemsByKeywords(keywords: string, maxRecords: number, rateGroupId?: string, searchAll = false): Observable<SorLookupValue[]> {
		let httpParams: HttpParams = new HttpParams().append('maxRecords', maxRecords);
		if (keywords) httpParams = httpParams.append('keywords', keywords);
		if (rateGroupId) httpParams = httpParams.append('rateGroupId', rateGroupId);
		if (searchAll) httpParams = httpParams.append('searchAll', true);

		return this.apiService.get<SorLookupValue[]>('/Lookup/GetProblemsByKeywords', httpParams);
	}

	getBusinessEntitiesForAtFaultReview(): Observable<BusinessEntityLookupValue[]> {
		return this.apiService.get<BusinessEntityLookupValue[]>(`/Lookup/GetBusinessEntitiesForAtFaultReview`);
	}

	getBusinessEntities(): Observable<BusinessEntityLookupValue[]> {
		return this.apiService.get<BusinessEntityLookupValue[]>(`/Lookup/GetBusinessEntities`);
	}

	getClients(): Observable<StatusLookupValue[]> {
		return this.apiService.get<StatusLookupValue[]>(`/Lookup/GetClients`);
	}

	getCompanies(): Observable<CompanyLookupValue[]> {
		return this.apiService.get<CompanyLookupValue[]>(`/Lookup/GetCompanies`);
	}

	getClientsCompanies(): Observable<GroupLookupValue[]> {
		return this.apiService.get<GroupLookupValue[]>(`/Lookup/GetClientCompanyLookups`);
	}

	getRateGroups(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetRateGroups`);
	}

	getRateGroupsForRateGroup(rateGroupId: string): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams().append('rateGroupId', rateGroupId);

		return this.apiService.get<LookupValue[]>(`/Lookup/GetRateGroupsForRateGroup`, httpParams);
	}

	getServiceProjects(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetServiceProjects`);
	}

	getServiceAreas(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetServiceAreas`);
	}

	getMaps(referenceTypeCode: string, referenceId: string, activeOnly = true): Observable<LookupValue[]> {
		let httpParams: HttpParams = new HttpParams().append('activeOnly', activeOnly);
		if (referenceTypeCode) httpParams = httpParams.append('referenceTypeCode', referenceTypeCode);
		if (referenceId) httpParams = httpParams.append('referenceId', referenceId);

		return this.apiService.get<LookupValue[]>('/Lookup/GetMapLookups', httpParams);
	}

	getRateGroupsFromRequest(rateGroupRequest: DataRestrictionsRequest): Observable<LookupValue[]> {
		return this.apiService.post('/Lookup/GetRateGroupsFromRequest', rateGroupRequest) as Observable<LookupValue[]>;
	}

	// #endregion

	// #region -- Work Order Lookups --

	getWorkOrdersByKeywords(keywords: string, maxRecords: number, activeOnly = true, includeTradeTypes = true): Observable<OrderLookupValue[]> {
		let httpParams: HttpParams = new HttpParams()
			.append('maxRecords', maxRecords)
			.append('activeOnly', activeOnly)
			.append('includeTradeTypes', includeTradeTypes);

		if (keywords) httpParams = httpParams.append('keywords', keywords);

		return this.apiService.get<OrderLookupValue[]>('/Lookup/GetWorkOrdersByKeywords', httpParams);
	}

	getWorkOrder(workOrderId: string): Observable<OrderLookupValue> {
		return this.apiService.get<OrderLookupValue>(`/Lookup/GetWorkOrder/${workOrderId}`);
	}

	getWorkOrderItems(workOrderId: string, activeOnly = true): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetWorkOrderItems/${workOrderId}/${activeOnly}`);
	}

	getWorkOrderProperties(workOrderId: string, activeOnly = true): Observable<AssetLookupValue[]> {
		return this.apiService.get<AssetLookupValue[]>(`/Lookup/GetWorkOrderProperties/${workOrderId}/${activeOnly}`);
	}

	// #endregion

	// #region -- Purchase Order Lookups --

	getPurchaseOrdersByKeywords(keywords: string, maxRecords: number, activeOnly = true, includeTradeTypes = true): Observable<OrderLookupValue[]> {
		let httpParams: HttpParams = new HttpParams()
			.append('maxRecords', maxRecords)
			.append('activeOnly', activeOnly)
			.append('includeTradeTypes', includeTradeTypes);

		if (keywords) httpParams = httpParams.append('keywords', keywords);

		return this.apiService.get<OrderLookupValue[]>('/Lookup/GetPurchaseOrdersByKeywords', httpParams);
	}

	getPurchaseOrdersForWorkOrder(workOrderId: string, activeOnly = true, includeTradeTypes = true): Observable<OrderLookupValue[]> {
		return this.apiService.get<OrderLookupValue[]>(`/Lookup/GetPurchaseOrdersForWorkOrder/${workOrderId}/${activeOnly}/${includeTradeTypes}`);
	}

	getPurchaseOrderItems(purchaseOrderId: string, activeOnly = true): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetPurchaseOrderItems/${purchaseOrderId}/${activeOnly}`);
	}

	getPurchaseOrderTypesForDefectPriority(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetPurchaseOrderTypesForDefectPriority`);
	}

	getCombinedPurchaseOrderPriorities(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetCombinedPurchaseOrderPriorities`);
	}

	// #region -- Order Allocation Lookups --

	getPurchaseOrderLookupsForItemAllocation(
		workOrderId: string,
		businessEntityId: string,
		activeOnly = true,
		includeTradeTypes = true
	): Observable<OrderLookupValue[]> {
		return this.apiService.get<OrderLookupValue[]>(
			`/Lookup/GetPurchaseOrderLookupsForItemAllocation/${workOrderId}/${businessEntityId}/${activeOnly}/${includeTradeTypes}`
		);
	}

	// #endregion

	// #endregion

	// #region -- Scope Lookups --

	getScope(scopeId: string, activeOnly = true): Observable<TaxLookupValue> {
		return this.apiService.get<TaxLookupValue>(`/Lookup/GetScope/${scopeId}/${activeOnly}`);
	}

	getScopesByKeywords(keywords: string, maxRecords: number, activeOnly = true): Observable<LookupValue[]> {
		let httpParams: HttpParams = new HttpParams().append('maxRecords', maxRecords).append('activeOnly', activeOnly);
		if (keywords) httpParams = httpParams.append('keywords', keywords);

		return this.apiService.get<OrderLookupValue[]>('/Lookup/GetScopesByKeywords', httpParams);
	}

	// #endregion

	// #region -- Quote Request Lookups --

	getQuoteRequest(quoteRequestId: string, activeOnly = true): Observable<TaxLookupValue> {
		return this.apiService.get<TaxLookupValue>(`/Lookup/GetQuoteRequest/${quoteRequestId}/${activeOnly}`);
	}

	getQuoteRequestsByKeywords(keywords: string, maxRecords: number, activeOnly = true): Observable<LookupValue[]> {
		let httpParams: HttpParams = new HttpParams().append('maxRecords', maxRecords).append('activeOnly', activeOnly);
		if (keywords) httpParams = httpParams.append('keywords', keywords);

		return this.apiService.get<OrderLookupValue[]>('/Lookup/GetQuoteRequestsByKeywords', httpParams);
	}

	// #endregion

	// #region -- Invoice Lookups --

	getInvoice(invoiceId: string, activeOnly = true): Observable<InvoiceLookupValue> {
		return this.apiService.get<InvoiceLookupValue>(`/Lookup/GetInvoice/${invoiceId}/${activeOnly}`);
	}

	getInvoices(referenceTypeCode: string, referenceId: string, activeOnly = true): Observable<InvoiceLookupValue[]> {
		return this.apiService.get<InvoiceLookupValue[]>(`/Lookup/GetInvoices/${referenceTypeCode}/${referenceId}/${activeOnly}`);
	}

	getInvoicesAvailableForParentInvoice(referenceTypeCode: string, referenceId: string, isReceivable: boolean): Observable<InvoiceLookupValue[]> {
		return this.apiService.get<InvoiceLookupValue[]>(`/Lookup/GetInvoicesAvailableForParentInvoice/${referenceTypeCode}/${referenceId}/${isReceivable}`);
	}

	// #endregion

	// #region -- Shared Lookups --

	getContactLookups(
		assetId: string,
		scopeId: string,
		quoteRequestId: string,
		workOrderId: string,
		serviceRequestId: string,
		activeOnly = true
	): Observable<ContactLookupValue[]> {
		let httpParams: HttpParams = new HttpParams().append('activeOnly', activeOnly);

		if (assetId) httpParams = httpParams.append('assetId', assetId);
		if (scopeId) httpParams = httpParams.append('scopeId', scopeId);
		if (quoteRequestId) httpParams = httpParams.append('quoteRequestId', quoteRequestId);
		if (workOrderId) httpParams = httpParams.append('workOrderId', workOrderId);
		if (serviceRequestId) httpParams = httpParams.append('serviceRequestId', serviceRequestId);

		return this.apiService.get<ContactLookupValue[]>('/Lookup/GetContactLookups', httpParams);
	}

	//#endregion

	// #region -- Calendar Lookups --

	getRecordTypesForCalendarRecords(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>('/Lookup/GetRecordTypesForCalendarRecords');
	}

	getRecordTypesForCalendarResources(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>('/Lookup/GetRecordTypesForCalendarResources');
	}

	getDefaultCalendarResourceForAssetType(assetTypeCode: string, activeOnly = true, keywords: string = null): Observable<LookupValue[]> {
		let httpParams: HttpParams = new HttpParams();
		if (assetTypeCode) httpParams = httpParams.append('assetTypeCode', assetTypeCode);
		if (activeOnly) httpParams = httpParams.append('activeOnly', activeOnly);
		if (keywords) httpParams = httpParams.append('keywords', keywords);
		return this.apiService.get<LookupValue[]>('/Lookup/GetDefaultCalendarResourceForAssetType', httpParams);
	}

	getCalendarRecordByKeywords(keywords: string, refTypes: string): Observable<RecordLookupValue[]> {
		let httpParams: HttpParams = new HttpParams();
		if (keywords) httpParams = httpParams.append('keywords', keywords);
		if (refTypes) httpParams = httpParams.append('recordTypeCodes', refTypes);

		return this.apiService.post<RecordLookupValue[]>('/Lookup/GetCalendarRecordByKeywordsViewFilter', this.util.getViewByFilter(), httpParams);
	}

	getCalendarRecord(refType: string, refId: string | null): Observable<RecordLookupValue> {
		let httpParams: HttpParams = new HttpParams();
		if (refType) httpParams = httpParams.append('recordTypeCode', refType);
		if (refId) httpParams = httpParams.append('recordId', refId);

		return this.apiService.get<RecordLookupValue>(`/Lookup/GetCalendarRecord/`, httpParams);
	}

	getCalendarResourceByKeywords(keywords: string, refTypes: string | null): Observable<CalendarResourceLookupValue[]> {
		let httpParams: HttpParams = new HttpParams();
		if (keywords) httpParams = httpParams.append('keywords', keywords);
		if (refTypes) httpParams = httpParams.append('recordTypeCodes', refTypes);

		return this.apiService.post<CalendarResourceLookupValue[]>(`/Lookup/GetCalendarResourceByKeywordsViewFilter`, this.util.getViewByFilter(), httpParams);
	}

	//#endregion

	// #region -- Settings Lookups --

	getDataTemplates(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>('/Lookup/GetDataTemplates');
	}

	getSubscriberTimezones(): Observable<string> {
		return this.apiService.get<string>('/Lookup/GetSubscriberTimezones');
	}

	//#endregion

	// #region -- Custom Lookups --

	getInvoiceTargetByKeywords(keywords: string, maxRecords: number): Observable<InvoiceTargetLookupValue[]> {
		let httpParams: HttpParams = new HttpParams().append('maxRecords', maxRecords);
		if (keywords) httpParams = httpParams.append('keywords', keywords);

		return this.apiService.get<InvoiceTargetLookupValue[]>('/Lookup/GetInvoiceTargetByKeywords', httpParams);
	}

	getTimezoneCodeFromLatLong(latitude: number, longitude: number): Observable<string> {
		return this.apiService.get<string>(`/Lookup/GetTimezoneCodeFromLatLong/${latitude}/${longitude}`);
	}

	getServiceRequestTypesForCurrentUserCreation(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>('/Lookup/GetServiceRequestTypesForCurrentUserCreation');
	}

	getUserPermissionLookupsForUserRelationType(userRelationTypeCode: string): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetUserPermissionsForUserRelationType/${userRelationTypeCode}`);
	}

	getBusinessEntitiesForAllocation(): Observable<BusinessEntityLookupValue[]> {
		return this.apiService.get<BusinessEntityLookupValue[]>(`/Lookup/GetBusinessEntitiesForAllocation`);
	}

	getFinancialEntities(): Observable<FinancialEntityLookupValue[]> {
		return this.apiService.get<FinancialEntityLookupValue[]>(`/Lookup/GetFinancialEntities`);
	}

	getReferenceTypesForFlagAlerts(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetReferenceTypesForFlagAlerts`);
	}

	getFlagAlertTypesForReferenceType(referenceTypeCode: string): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetFlagAlertTypesForReferenceType/${referenceTypeCode}`);
	}

	getAssetCategoriesForAssetType(assetTypeCode: string): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetAssetCategoriesForAssetType/${assetTypeCode}`);
	}

	getSubscriberDomainValues(subscriberDomainTypeCode: string): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetSubscriberDomainValues/${subscriberDomainTypeCode}`);
	}

	getReferenceValues(referenceTypeCode: string): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetReferenceValues/${referenceTypeCode}`);
	}

	getUserAllocations(): Observable<UserAllocationLookupValue[]> {
		return this.apiService.get<UserAllocationLookupValue[]>(`/Lookup/GetUserAllocations`);
	}

	getUserAllocationsFromRequest(rateGroupRequest: DataRestrictionsRequest): Observable<UserAllocationLookupValue[]> {
		return this.apiService.post('/Lookup/GetUserAllocationsFromRequest', rateGroupRequest) as Observable<UserAllocationLookupValue[]>;
	}

	//#endregion

	// #region -- User Lookups --

	getInternalUserRoles(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetInternalUserRoles`);
	}

	getClientUserRoles(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetClientUserRoles`);
	}

	getUserTeams(): Observable<StatusLookupValue[]> {
		return this.apiService.get<StatusLookupValue[]>(`/Lookup/GetUserTeams`);
	}

	getInternalUserProfiles(activeOnly = true): Observable<UserProfileLookupValue[]> {
		return this.apiService.get<UserProfileLookupValue[]>(`/Lookup/GetInternalUserProfiles/${activeOnly}`);
	}

	//#endregion

	// #region -- Interface Lookups --

	getIntegrationEventTypesAvailableForImport(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetIntegrationEventTypesAvailableForImport`);
	}

	//#endregion

	// #region -- App-Only Lookups --

	getCountryNames(): Observable<LookupValue[]> {
		const httpParams: HttpParams = new HttpParams();
		return this.apiService.get<LookupValue[]>('/Lookup/GetCountries', httpParams).pipe(switchMap(x => this.prepareCountryLookup(x)));
	}

	private prepareCountryLookup(list: LookupValue[]): Observable<LookupValue[]> {
		if (list === null) return of(null as LookupValue[]);

		return of(
			list.map(
				x =>
					new LookupValue({
						CodeValue: x.NameValue,
						NameValue: x.NameValue,
						ExtendedCodeValue: x.NameValue,
						ExtendedNameValue: x.NameValue,
					})
			)
		);
	}

	//#endregion

	//# region  --  User Team Lookups --

	getClientsForUserTeam(): Observable<StatusLookupValue[]> {
		return this.apiService.get<StatusLookupValue[]>(`/Lookup/GetClientsForUserTeam`);
	}

	getServiceProjectsForUserTeam(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetServiceProjectsForUserTeam`);
	}

	getServiceAreasForUserTeam(): Observable<LookupValue[]> {
		return this.apiService.get<LookupValue[]>(`/Lookup/GetServiceAreasForUserTeam`);
	}

	//#endregion
}
