import { BulkActionConfirmationIconConstants } from 'src/app/shared/constants/bulk-action-confirmation-icon.constants';
import { BulkActionNamesConstants } from 'src/app/shared/constants/bulk-action-names.constants';
import { COLUMNTYPES } from 'src/app/shared/constants/column.constants';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { Column } from 'src/app/shared/models/shared/column';

export class PurchaseOrderConstants {
	//FilterActions
	static readonly FilterActionsList = [
		BulkActionNamesConstants.ActionName_Created,
		BulkActionNamesConstants.ActionName_Allocated,
		BulkActionNamesConstants.ActionName_InProgress,
		BulkActionNamesConstants.ActionName_DataEntryRequired,
		BulkActionNamesConstants.ActionName_ReviewRequired,
		BulkActionNamesConstants.ActionName_ReviewApproved,
		BulkActionNamesConstants.ActionName_Pause,
		BulkActionNamesConstants.ActionName_Cancel,
	];

	//BulkAction ActionIcons
	static readonly ActionIconsNames = [
		{ action: BulkActionNamesConstants.ActionName_Created, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Created },
		{ action: BulkActionNamesConstants.ActionName_Allocated, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Allocated },
		{ action: BulkActionNamesConstants.ActionName_InProgress, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_InProgress },
		{ action: BulkActionNamesConstants.ActionName_DataEntryRequired, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_DataEntryRequired },
		{ action: BulkActionNamesConstants.ActionName_ReviewRequired, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_ReviewRequired },
		{ action: BulkActionNamesConstants.ActionName_ReviewApproved, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_ReviewAndApproved },
		{ action: BulkActionNamesConstants.ActionName_Pause, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Pause },
		{ action: BulkActionNamesConstants.ActionName_Cancel, icon: BulkActionConfirmationIconConstants.Confirmation_Title_Icon_Cancel },
	];

	static readonly BulkAction_Object_Name = 'Purchase Order';
	static readonly BulkAction_Object_PropertyName_Number = 'PurchaseOrderNumber';

	static readonly COLUMNS_PO: Column[] = [
		{ order: 1, header: 'PO #', field: 'PurchaseOrderNumber', width: 100 },
		{ order: 2, header: 'WO #', field: 'WorkOrderNumber', width: 100 },
		{ order: 3, header: 'Allocated To', field: 'BusinessEntityName' },
		{ order: 4, header: 'Asset Name', field: 'AssetReference', dataTestId: 'purchaseOrderAssetName' },
		{ order: 5, header: 'Address', field: 'Street' },
		{ order: 6, header: 'Suburb', field: 'Suburb' },
		{ order: 7, header: 'Status', field: 'StatusName' },
		{ order: 8, header: 'Type', field: 'PurchaseOrderTypeName' },
		{ order: 9, header: 'Priority', field: 'PurchaseOrderPriorityCode' },
		{ order: 10, header: 'Order Owner', field: 'OwnerName' },
		{
			order: 11,
			header: 'Due',
			field: 'TargetDate',
			type: COLUMNTYPES.DATETIME,
			isInnerHtmlFormat: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.DATE,
		},
		{
			order: 12,
			header: 'Completed',
			field: 'DateWorkCompleted',
			type: COLUMNTYPES.DATETIME,
			isInnerHtmlFormat: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.DATE,
		},
		{
			order: 13,
			header: 'Cost',
			field: 'CostAmountInclTax',
			type: COLUMNTYPES.NUMERIC,
			isConditional: true,
			hasHeaderSmallLabel: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.NUMERIC,
			isPrice: true,
		},
		{
			order: 14,
			header: 'Invoiced',
			field: 'InvoicedCostAmountInclTax',
			type: COLUMNTYPES.NUMERIC,
			isConditional: true,
			hasHeaderSmallLabel: true,
			hasFilterHeaderTemplate: true,
			isFilterCallback: true,
			filterType: COLUMNTYPES.NUMERIC,
			isPrice: true,
		},
	];

	// dialog messages
	static readonly Dialog_ConfirmCancelPurchaseOrder: string = 'Do you want to cancel this purchase order?';

	static readonly Page_CancelPurchaseOrder: string = 'Cancel Purchase Order';
	static readonly Page_FlowToReceivableInvoice: string = 'Create New Receivable Invoice from Purchase Order.';
	static readonly Page_FlowToPayableInvoice: string = 'Create New Payable Invoice from Purchase Order.';
	static readonly Page_ViewPlan: string = 'Calendar';

	static readonly Tab_ItemsList_AddItem: string = 'Add Item';
	static readonly Tab_ItemsList_SendChanges: string = 'Save & Send';
	static readonly Tab_ItemsList_ItemRow_Edit: string = 'Edit';

	static readonly Tab_Item_Delete: string = 'Delete Item';
	static readonly Tab_Item_Edit: string = 'Edit Item';

	static readonly Tab_Details_Edit: string = 'Edit Purchase Order';

	static readonly Tab_detail_Parent = () => `Back To Parent`;

	static readonly Type_BaseCSS = (typeName: string) =>
		typeName == Constants.PurchaseOrderType.Defect || typeName == Constants.PurchaseOrderType.DefectNotification
			? Constants.BasePageCSSClass.Defect
			: typeName == Constants.PurchaseOrderType.Recovery
			? Constants.BasePageCSSClass.Recovery
			: typeName == Constants.PurchaseOrderType.Credit
			? Constants.BasePageCSSClass.Credit
			: Constants.BasePageCSSClass.PurchaseOrder;
}
