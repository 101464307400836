<p-table
	#relatedWOTable
	*ngIf="showList"
	[value]="data"
	dataKey="data"
	[rowHover]="true"
	[rows]="50"
	[totalRecords]="totalRecords"
	[paginator]="true"
	[lazy]="true"
	responsiveLayout="scroll"
	[filterDelay]="0"
	[rowsPerPageOptions]="[10, 25, 50]"
	[showCurrentPageReport]="true"
	currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
	[loading]="loading"
	[filterDelay]="0"
	(onLazyLoad)="loadData($event)">
	<ng-template pTemplate="header">
		<tr class="related-wo-table-row">
			<th pSortableColumn="ReferenceName" data-testid="relatedWOReference">
				<div class="table-header-label">
					WO #<p-sortIcon field="ReferenceName"> </p-sortIcon>
					<p-columnFilter type="text" field="ReferenceName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="AssetNumber" data-testid="relatedWONumber">
				<div class="table-header-label">
					Asset #<p-sortIcon field="AssetNumber"> </p-sortIcon>
					<p-columnFilter type="text" field="AssetNumber" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="AssetReference" data-testid="relatedWOName">
				<div class="table-header-label">
					Asset Name<p-sortIcon field="AssetReference"> </p-sortIcon>
					<p-columnFilter type="text" field="AssetReference" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="Street" data-testid="relatedAddress">
				<div class="table-header-label">
					Address<p-sortIcon field="Street"> </p-sortIcon>
					<p-columnFilter type="text" field="Street" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="Suburb" data-testid="suburb">
				<div class="table-header-label">
					Suburb<p-sortIcon field="Suburb"> </p-sortIcon>
					<p-columnFilter type="text" field="Suburb" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>

			<th pSortableColumn="StatusName" data-testid="relatedWOStatus">
				<div class="table-header-label">
					Status<p-sortIcon field="StatusName"> </p-sortIcon>
					<p-columnFilter type="text" field="StatusName" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>

			<th pSortableColumn="ScheduledStartDate">
				<div class="table-header-label">
					Scheduled Start<p-sortIcon field="ScheduledStartDate"></p-sortIcon>
					<p-columnFilter type="date" field="ScheduledStartDate" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateCompleted">
				<div class="table-header-label">
					Due<p-sortIcon field="TargetDate"></p-sortIcon>
					<p-columnFilter type="date" field="TargetDate" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateCompleted">
				<div class="table-header-label">
					Completed<p-sortIcon field="DateCompleted"></p-sortIcon>
					<p-columnFilter type="date" field="DateCompleted" display="menu" class="ml-auto"> </p-columnFilter>
				</div>
			</th>
			<th *ngIf="showLink()" style="width: 3rem"></th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-woDetail>
		<tr class="wo-detail-row" data-testid="relatedWORow">
			<td>
				<span class="is-completed-style"> <i class="fa fa-circle dot-{{ woDetail.RecordTypeCode?.toLowerCase() }}"></i></span> {{ woDetail.ReferenceName }}
			</td>
			<td>
				<span class="is-completed-style"> <i class="fa fa-circle dot-{{ woDetail.AssetRecordTypeCode?.toLowerCase() }}"></i></span> {{ woDetail.AssetNumber }}
			</td>
			<td>{{ woDetail.AssetReference }}</td>
			<td>{{ woDetail.Street }}</td>
			<td>{{ woDetail.Suburb }}</td>
			<td>{{ woDetail.StatusName }}</td>

			<td>
				<span [innerHTML]="woDetail.ScheduledStartDate | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td>
				<span [innerHTML]="woDetail.TargetDate | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td>
				<span [innerHTML]="woDetail.DateCompleted | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></span>
			</td>
			<td *ngIf="showLink()" style="text-align: center; padding-left: 0px; padding-right: 0px">
				<button
					*ngIf="canNavigateToDetails(woDetail)"
					type="button"
					pButton
					pRipple
					pTooltip="Click For {{ getRowTypeName(woDetail) ?? woDetail.RecordTypeCode }}"
					tooltipPosition="bottom"
					(click)="handleRowClick(woDetail)"
					class="p-button-text p-button-rounded p-button-plain"
					icon="pi pi-link"></button>
				<div
					*ngIf="!canNavigateToDetails(woDetail)"
					class="p-element p-button-text p-button-rounded p-button-plain p-button p-component p-button-icon-only ng-star-inserted"></div>
			</td>
		</tr>
	</ng-template>
</p-table>
