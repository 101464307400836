<p-toolbar *ngIf="showList">
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="button-with-tooltip-holder" *ngIf="showAddItem() && !includeRelated" pTooltip="Add Attachment" tooltipPosition="bottom">
			<p-button data-testid="attachmentListAddButton" label="Add" icon="pi pi-plus" (onClick)="addClick($event)"> </p-button>
		</div>
	</ng-template>
</p-toolbar>
<p-table
	*ngIf="showList"
	#attachmentsTable
	[value]="data"
	editMode="row"
	[rowHover]="true"
	responsiveLayout="scroll"
	[filterDelay]="0"
	dataKey="Details.AttachmentId"
	[rows]="50"
	[lazy]="true"
	[loading]="loading"
	[paginator]="false"
	[totalRecords]="totalRecords"
	[rowsPerPageOptions]="[10, 25, 50]"
	(onLazyLoad)="loadData($event)">
	<ng-template pTemplate="header">
		<tr>
			<th pSortableColumn="AttachmentTypeName">
				<div style="white-space: nowrap">
					Type
					<p-sortIcon field="AttachmentTypeName"></p-sortIcon>
					<p-columnFilter type="text" field="AttachmentTypeName" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="AttachmentTitle">
				<div style="white-space: nowrap">
					Title
					<p-sortIcon field="AttachmentTitle"></p-sortIcon>
					<p-columnFilter type="text" field="AttachmentTitle" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th *ngIf="includeRelated" pSortableColumn="ParentReferenceName">
				<div style="white-space: nowrap">
					Reference
					<p-sortIcon field="ParentReferenceName"></p-sortIcon>
					<p-columnFilter type="text" field="ParentReferenceName" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th *ngIf="showParentReferenceColumn" pSortableColumn="ParentReferenceName">
				<div style="white-space: nowrap">
					Parent Reference
					<p-sortIcon field="ParentReferenceName"></p-sortIcon>
					<p-columnFilter type="text" field="ParentReferenceName" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="ReferenceName">
				<div style="white-space: nowrap">
					Reference
					<p-sortIcon field="ReferenceName"></p-sortIcon>
					<p-columnFilter type="text" field="ReferenceName" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="DateAdded">
				<div style="white-space: nowrap">
					Created
					<p-sortIcon field="DateAdded"></p-sortIcon>
					<p-columnFilter type="date" field="DateAdded" display="menu"> </p-columnFilter>
				</div>
			</th>
			<th pSortableColumn="AddedBy">
				<div style="white-space: nowrap">
					Created by
					<p-sortIcon field="AddedBy"></p-sortIcon>
					<p-columnFilter type="text" field="AddedBy" display="menu"> </p-columnFilter>
				</div>
			</th>

			<th style="width: 3rem"></th>
			<th style="width: 3rem"></th>
		</tr>
		<tr></tr>
	</ng-template>
	<ng-template pTemplate="body" let-editing="editing" let-attachment let-rowIndex="rowIndex">
		<tr class="p-selectable-row" [pEditableRow]="attachment">
			<td>
				{{ attachment.Details.AttachmentTypeName }}
			</td>
			<td>
				{{ attachment.Details.AttachmentTitle }}
			</td>
			<td *ngIf="includeRelated">
				{{ attachment.Details.ParentReferenceName }}
			</td>
			<td *ngIf="showParentReferenceColumn">
				{{ attachment.Details.ParentReferenceName }}
			</td>
			<td>
				{{ attachment.Details.ReferenceName }}
			</td>
			<td [innerHTML]="attachment.Details.DateAdded | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></td>
			<td>
				{{ attachment.Details.AddedBy }}
			</td>
			<td style="text-align: center">
				<button
					pButton
					pRipple
					type="button"
					icon="pi pi-download"
					(click)="downloadAllFiles(attachment)"
					class="p-button-rounded p-button-text"
					pTooltip="Download All Files"
					tooltipPosition="bottom"></button>
			</td>

			<td>
				<button
					type="button"
					pButton
					pRipple
					pTooltip="Expand"
					tooltipPosition="bottom"
					(click)="expandClick(attachment)"
					class="p-button-text p-button-rounded p-button-plain"
					[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
					data-testid="attachmentListExpandButton"></button>
			</td>
		</tr>
		<tr class="attachment-children" *ngIf="attachment.Files && attachment.Files.length > 0">
			<td colspan="10">
				<div class="card" header="Attachment Files" *ngIf="attachment.Files.length > 0">
					<p-table [value]="attachment.Files" dataKey="AttachmentFileId">
						<ng-template pTemplate="header">
							<!--<tr *ngIf="attachment.Files && attachment.Files.length > 0">
								<th></th>
								<th>Filename</th>
								<th>Created</th>
								<th></th>
							</tr>-->
						</ng-template>
						<ng-template pTemplate="body" let-file *ngIf="attachment.Files && attachment.Files.length > 0">
							<tr class="attach-list">
								<td class="attach-img-col" style="text-align: center">
									<!-- <p-image *ngIf="isValidImage(file)" src="{{'data:image/png;base64,' + file.FileData}}" width="100"
                    [preview]="true"></p-image> -->
									<img *ngIf="isValidImage(file)" src="{{ 'data:image/png;base64,' + file.FileData }}" style="cursor: pointer" (click)="thumbnailClick(file)" />
									<i
										*ngIf="!isValidImage(file)"
										(click)="clickEvent(file)"
										class="{{ util.getFileIcon(file.AttachmentFileName) }}"
										style="font-size: 4rem; cursor: pointer"></i>
								</td>
								<td class="attach-filename-col">
									<div
										class="timeformat-attachment"
										[innerHTML]="file.DateAdded | formatDate : region.RegionCode : region.DateFormat : region.TimeFormat"></div>
									<div>{{ file.AttachmentFileName }}</div>
								</td>
								<td>
									<button
										pButton
										pRipple
										type="button"
										icon="pi pi-download"
										(click)="downloadFile(file)"
										class="p-button-rounded p-button-text"
										pTooltip="Download File"
										tooltipPosition="bottom"></button>
								</td>

								<td></td>
							</tr>
						</ng-template>
					</p-table>
				</div>
			</td>
		</tr>
	</ng-template>
</p-table>
<div class="custom-pagination" *ngIf="showList">
	<span>Showing {{ data == null ? 0 : data.length }} entries</span>
</div>
<app-attachment-details
	*ngIf="!showList"
	[readonly]="!editMode"
	[formMode]="formMode"
	[attachment]="currentAttachment"
	[images]="imagesCurrentAttachment"
	[parentReferenceTypeCode]="referenceTypeCode"
	[parentReferenceId]="referenceId"
	[showParentReferenceColumn]="showParentReferenceColumn"
	(displayList)="displayList($event)"
	(attachmentUpdated)="attachmentUpdated()">
</app-attachment-details>
<p-galleria
	[(value)]="imagesCurrentAttachment"
	[(visible)]="displayPreview"
	[(activeIndex)]="activeIndex"
	[containerStyle]="{ 'max-width': 'auto', 'max-height': '900px' }"
	[numVisible]="7"
	[circular]="true"
	[fullScreen]="true"
	[showItemNavigators]="true"
	[showThumbnails]="false"
	thumbnailsPosition="left"
	[baseZIndex]="100000">
	<ng-template pTemplate="item" let-item>
		<img [src]="item.PreviewImage" style="max-width: auto; max-height: 900px; display: block" />
	</ng-template>
</p-galleria>
