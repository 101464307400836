<div class="layout-topbar">
	<a routerLink="/" *ngIf="appMain.isHorizontal() && !appMain.isMobile()" class="topbar-logo">
		<img alt="logo" src="assets/layout/images/spydertechlogo-header.png" />
		<span class="app-name">SpyderFlow</span>
	</a>

	<img alt="logo" src="images/spydertechlogo-header.png" class="mobile-logo" />

	<a href="#" class="menu-btn" (click)="appMain.onMenuButtonClick($event)">
		<i class="pi pi-bars"></i>
	</a>

	<a href="#" class="topbar-menu-btn" (click)="appMain.onTopbarMobileMenuButtonClick($event)">
		<i class="pi pi-cog"></i>
	</a>
	<div class="header-container" data-testid="pageHeaderContainer">
		<ng-container *ngIf="!appContext.showBreadcrumb">
			<div *ngIf="appContext.ViewTitle" class="view-title-style" data-testid="headerTitleBreadcrumbHolder">
				{{ appContext.ViewTitle }}
				<div>
					<p-chip *ngIf="showDemoBadge()" class="subscription-demo-badge" label="demo"></p-chip>
					<p-chip *ngIf="showTestBadge()" class="subscription-test-badge" label="sandbox"></p-chip>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="appContext.showBreadcrumb">
			<div *ngIf="appContext.ViewTitle" class="view-title-style with-breadcrumb" data-testid="headerTitleBreadcrumbHolder">
				{{ appContext.ViewTitle }}
				<ul class="breadcrumb-item">
					<li *ngFor="let item of appContext.breadcrumbItems">
						<a
							*ngIf="item.url"
							data-testid="breadcrumbNavigate"
							[routerLink]="item.url"
							routerLinkActive="active-link"
							[routerLinkActiveOptions]="{ exact: true }">
							{{ item.label }}
						</a>
						<div *ngIf="!item.url" class="disabled-link" data-testid="breadcrumbNavigate">
							{{ item.label }}
						</div>
					</li>
				</ul>
				<div class="topbar-title-badge-holder" *ngIf="showDemoBadge() || showTestBadge()">
					<p-chip *ngIf="showDemoBadge()" class="subscription-demo-badge" label="demo"></p-chip>
					<p-chip *ngIf="showTestBadge()" class="subscription-test-badge" label="sandbox"></p-chip>
				</div>
			</div>
		</ng-container>
		<div class="layout-topbar-menu-wrapper flex">
			<ng-container *ngIf="appContext.showFilter && !appContext.getClientUser()">
				<ul class="topbar-menu fadeInDown">
					<div class="p-float-label">
						<p-multiSelect
							#viewByMultiselect
							id="viewBy"
							class="viewby-client-dropdown"
							[options]="appMain.itemGroups"
							[group]="true"
							[(ngModel)]="appMain.selectedItems"
							defaultLabel="View by"
							scrollHeight="250px"
							display="chip"
							[style]="{ width: '270px' }"
							[panelStyle]="{ width: '270px' }"
							(onChange)="onDropdownChange($event)"
							(onPanelHide)="onDropDownPanelHide()"
							data-testid="viewby-dropdown">
							<ng-template let-dropDown pTemplate="item">
								<div class="item-drop viewby item-option flex justify-content-between">
									<div class="item-value viewby wrap-label">
										{{ dropDown.label == null ? '' : dropDown.label }}
									</div>
									<span class="item-value viewby status">
										{{ dropDown.IsInactive == null ? '' : dropDown.IsInactive ? 'Inactive' : 'Active' }}
									</span>
								</div>
							</ng-template>
							<ng-template let-group pTemplate="group">
								<div class="viewby headline flex" *ngIf="group.label == 'COMPANY'">
									<i class="pi pi-id-card viewby icon md"></i>
									<div class="viewby label">Company</div>
								</div>
								<div class="viewby headline flex" *ngIf="group.label == 'CLIENT'">
									<i class="pi pi-briefcase viewby icon"></i>
									<div class="viewby label">Client</div>
								</div>
							</ng-template>
						</p-multiSelect>
						<label for="viewBy">View By</label>
					</div>
				</ul>
			</ng-container>
			<div class="topbar-user-card" *ngIf="getDisplayName() || getSubscriberName()">
				<span *ngIf="getDisplayName()" class="topbar-user-card-name" [pTooltip]="getDisplayName()" tooltipPosition="bottom">{{ getDisplayName() }}</span>
				<span *ngIf="getSubscriberName()" class="topbar-user-card-subscriber" [pTooltip]="getSubscriberName()" tooltipPosition="bottom">{{
					getSubscriberName()
				}}</span>
			</div>
			<ul
				class="topbar-menu fadeInDown user-profile-icon"
				[ngClass]="{
					'topbar-menu-active': appMain.topbarMenuActive
				}"
				(click)="appMain.onTopbarMenuClick($event)">
				<li
					#settings
					[ngClass]="{
						'active-topmenuitem': appMain.activeTopbarItem === settings
					}"
					(click)="appMain.onTopbarRootItemClick($event, settings)">
					<a href="#">
						<i class="topbar-icon pi pi-user"></i>
						<span class="topbar-item-name">Account Settings</span>
					</a>
					<ul class="fadeInDown">
						<li *ngIf="showProfileMenu()" role="menuitem" (click)="viewUserProfile()">
							<a href="#">
								<i class="pi pi-user-edit"></i>
								<span>Profile</span>
							</a>
						</li>
						<li role="menuitem" (click)="viewSubscriptions()">
							<a href="#">
								<i class="pi pi-sitemap"></i>
								<span>Change Subscription</span>
							</a>
						</li>
						<li role="menuitem" (click)="logout()">
							<a href="#">
								<i class="pi pi-power-off"></i>
								<span>Sign out</span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</div>
